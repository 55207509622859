import * as React from "react"
import styled from "styled-components"
import Title from "./Title"
import { tablet, smallMobile } from "../../constants/screenSizes"
import { lightBlack } from "../../constants/colors"

interface HeadingProps {
  margin?: string
  padding?: string
  color?: string
  className?: string
  children: React.ReactNode
}

const Heading = styled(Title)<{ margin?: string; padding?: string; color?: string }>`
  font-size: 40px;
  line-height: 50px;
  color: ${({ color }) => color || `${lightBlack}`};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || ""};
  @media (max-width: ${tablet}px) {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 1.2px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.96px;
  }
`

const LargeTitle: React.FC<HeadingProps> = ({
  margin,
  padding,
  color,
  children,
  className = "",
}): JSX.Element => (
  <Heading margin={margin} padding={padding} color={color} className={className}>
    {children}
  </Heading>
)

export default LargeTitle

import * as React from "react"
import styled from "styled-components"
import { lightBlack } from "../../constants/colors"
import { minionFont } from "../../constants/fonts"
import { tablet, smallMobile } from "../../constants/screenSizes"

const Heading = styled.h2<{ margin?: string; color?: string; fontFamily?: string }>`
  margin: ${({ margin }) => margin || 0};
  font-family: ${({ fontFamily }) => fontFamily || minionFont};
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 1.2px;
  text-transform: none;
  color: ${({ color }) => color || `${lightBlack}`};
  text-transform: none;
  @media (max-width: ${tablet}px) {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1.05px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.9px;
  }
`

interface TitleProps {
  margin?: string
  color?: string
  fontFamily?: string
  className?: string
  children: React.ReactNode
}

const Title: React.FC<TitleProps> = ({
  margin,
  color,
  fontFamily,
  className = "",
  children,
}): JSX.Element => (
  <Heading margin={margin} color={color} fontFamily={fontFamily} className={className}>
    {children}
  </Heading>
)

export default Title

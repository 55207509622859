import * as React from "react"
import FormFieldProps from "../../../../../../form/FormFieldProps"
import OutlinedInput from "../../../../../../form/OutlinedInput"
import { EnquiryFormStateType } from "../EnquiryForm"

const CompanyField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<EnquiryFormStateType>) => {
  return (
    <OutlinedInput
      name="company"
      onChange={onChange}
      value={state.company}
      label="Company Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={CompanyField.isValid(state)}
    />
  )
}

CompanyField.isValid = ({ company }) => {
  return company
}

export default CompanyField

import * as React from "react"
import styled from "styled-components"
import { lightBlack } from "../../constants/colors"
import { gillFont } from "../../constants/fonts"
import { screenSizes } from "@social-supermarket/social-supermarket-model"

const Text = styled.p<{
  margin?: string
  tabletMargin?: string
  color?: string
  lineHeight?: string
  fontFamily?: string
}>`
  margin: ${({ margin }) => margin || 0};
  font-family: ${({ fontFamily }) => fontFamily || gillFont};
  font-size: 18px;
  line-height: ${({ lineHeight }) => lineHeight || "32px"};
  letter-spacing: 0.8px;
  color: ${({ color }) => color || `${lightBlack}`};

  a,
  button {
    color: ${({ color }) => color || `${lightBlack}`} !important;
    text-decoration: underline;
  }

  @media (max-width: ${screenSizes.tablet}px) {
    margin: ${({ tabletMargin, margin }) => tabletMargin || margin || 0};
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.71px;
  }
`

interface DescriptionProps {
  margin?: string
  tabletMargin?: string
  color?: string
  lineHeight?: string
  className?: string
  fontFamily?: string
  children: React.ReactNode
}

const Description: React.FC<DescriptionProps> = ({
  margin,
  tabletMargin,
  color,
  lineHeight,
  className = "",
  children,
  fontFamily,
}): JSX.Element => (
  <Text
    margin={margin}
    fontFamily={fontFamily}
    tabletMargin={tabletMargin}
    color={color}
    lineHeight={lineHeight}
    className={className}
  >
    {children}
  </Text>
)

export default Description

import * as React from "react"
import styled from "styled-components"
import { largeDesktop } from "../../constants/screenSizes"

const Container = styled.div<{ padding?: string }>`
  width: 100%;
  padding: ${({ padding }) => padding || "0 65px"};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${largeDesktop}px) {
    padding: 0;
  }
`

interface InnerBlockProps {
  padding?: string
  className?: string
  children?: React.ReactNode
}

const InnerBlock: React.FC<InnerBlockProps> = ({
  padding,
  children,
  className = "",
}): JSX.Element => (
  <Container className={className} padding={padding}>
    {children}
  </Container>
)

export default InnerBlock

import * as React from "react"
import styled from "styled-components"

const Container = styled.div<{ marginBottom: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  position: relative;
  flex: 1;
`
const TArea = styled.textarea<{ height: string }>`
  padding: 15px 15px !important;
  border-radius: 5px;
  width: 100%;
  ${({ height }) => height && `height: ${height};`}
  border: ${({ valid }) => !valid && "1px solid red !important"};
`

const handleChange = onChange => event => {
  onChange(event.target.name, event.target.value)
}

const TextArea = ({
  name,
  onChange,
  disabled,
  value,
  label,
  isValid,
  validate,
  height,
  marginBottom = "20px",
}) => {
  const valid = validate === false || isValid === undefined || isValid
  return (
    <Container marginBottom={marginBottom}>
      <TArea
        id={name}
        name={name}
        height={height}
        onChange={handleChange(onChange)}
        value={value}
        disabled={disabled}
        placeholder={label}
        valid={valid}
      />
    </Container>
  )
}

export default TextArea

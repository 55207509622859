import * as React from "react"
import styled from "styled-components"
import { lightBlack, white } from "../../constants/colors"
import { gillFont } from "../../constants/fonts"
import { smallMobile, tablet } from "../../constants/screenSizes"

const Heading = styled.h3<{ color?: string; isBordered?: boolean; borderColor?: string }>`
  margin: 0;
  display: inline-block;
  font-family: ${gillFont};
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ color }) => color || lightBlack};
  ${({ isBordered }) =>
    isBordered &&
    `
    padding-bottom: 9px;
    border-bottom: 2px solid;
  `};
  border-color: ${({ borderColor }) => borderColor || white};
  @media (max-width: ${tablet}px) {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.88px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
  }
`

interface SubheadingProps {
  color?: string
  isBordered?: boolean
  borderColor?: string
  className?: string
  children: React.ReactNode
}

const Subheading: React.FC<SubheadingProps> = ({
  color,
  isBordered,
  borderColor,
  className = "",
  children,
}): JSX.Element => (
  <Heading color={color} isBordered={isBordered} borderColor={borderColor} className={className}>
    {children}
  </Heading>
)

export default Subheading

import * as React from "react"
import styled from "styled-components"
import { desktop } from "../../constants/screenSizes"

const Container = styled.div<{
  width?: string
  padding?: string
  margin?: string
  wrapPadding?: string
  wrapOrder?: string
}>`
  width: ${({ width }) => width || "50%"};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  @media (max-width: ${desktop}px) {
    width: 100%;
    padding: ${({ wrapPadding }) => wrapPadding || 0};
    order: ${({ wrapOrder }) => wrapOrder || 0};
  }
`

interface PartProps {
  width?: string
  padding?: string
  margin?: string
  wrapPadding?: string
  className?: string
  wrapOrder?: string
  children: React.ReactNode
}

const Part: React.FC<PartProps> = ({
  width,
  padding,
  margin,
  wrapPadding,
  className = "",
  children,
  wrapOrder,
}): JSX.Element => (
  <Container
    width={width}
    padding={padding}
    margin={margin}
    wrapPadding={wrapPadding}
    className={className}
    wrapOrder={wrapOrder}
  >
    {children}
  </Container>
)

export default Part

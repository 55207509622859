import * as React from "react"
import FormFieldProps from "../../../../../../form/FormFieldProps"
import OutlinedInput from "../../../../../../form/OutlinedInput"
import { EnquiryFormStateType } from "../EnquiryForm"

const FirstNameField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<EnquiryFormStateType>) => {
  return (
    <OutlinedInput
      name="firstName"
      onChange={onChange}
      value={state.firstName}
      label="First Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={FirstNameField.isValid(state)}
    />
  )
}

FirstNameField.isValid = state => {
  return state.firstName
}

export default FirstNameField

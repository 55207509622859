import * as React from "react"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"
import { white } from "../../../../../constants/colors"
import LogoTheGuardian from "../../../../../../images/logo-the-guardian.jpg"
import LogoTheObserver from "../../../../../../images/logo-the-observer.jpg"
import LogoWhich from "../../../../../../images/logo-which.jpg"
import LogoTheTimes from "../../../../../../images/logo-the-times.jpg"
import LogoTheTelegraph from "../../../../../../images/logo-the-telegraph.jpg"
import LogoPioneersPost from "../../../../../../images/logo-pioneers-post.jpg"
import LogoEnki from "../../../../../../images/logo-enki.jpg"
import LogoCountryTownHouse from "../../../../../../images/logo-country&town-house.jpg"
import LogoTheFinancialTimes from "../../../../../../images/logo-the-financial-times.jpg"
import LogoRetailTimes from "../../../../../../images/logo-retail-times.jpg"
import LogoReuters from "../../../../../../images/logo-reuters.jpg"
import LogoPalife from "../../../../../../images/logo-palife.jpg"
import Carousel from "../../../../carousel/Carousel"
import { desktop } from "../../../../../constants/screenSizes"
import LPBody from "../layout/LPBody"
import LPBlock from "../layout/LPBlock"
import LpIndentedTitle from "../layout/LPIndentedTitle"

const CarouselContainer = styled.div``

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: ${desktop}px) {
    padding-bottom: 50px;
  }
`

const logos = [
  {
    image: LogoTheGuardian,
    link: "https://www.theguardian.com/money/2021/mar/21/alternatives-to-amazon-that-deliver-on-value-and-ethics",
  },
  {
    image: LogoTheObserver,
    link: "https://www.theguardian.com/money/2021/dec/05/how-to-have-yourself-a-very-merry-eco-friendly-christmas",
  },
  {
    image: LogoWhich,
    link: "https://www.which.co.uk/news/2021/08/what-does-amazons-climate-pledge-friendly-label-really-mean/",
  },
  {
    image: LogoTheTimes,
    link: "https://www.thetimes.co.uk/article/the-best-ethical-gifts-and-experiences-for-travel-lovers-kfk9s6gxc",
  },
  {
    image: LogoTheTelegraph,
    link: "https://www.telegraph.co.uk/food-and-drink/features/create-stylish-christmas-hamper/",
  },
  {
    image: LogoPioneersPost,
    link: "https://www.pioneerspost.com/news-views/20211203/three-ways-buy-social-christmas-and-10-gifts-we-love",
  },
  {
    image: LogoEnki,
  },
  {
    image: LogoCountryTownHouse,
    link: "https://www.countryandtownhouse.co.uk/culture/fairtrade-fortnight-get-involved/",
  },
  {
    image: LogoTheFinancialTimes,
    link: "https://www.ft.com/content/1e930bbb-59c3-477f-91c3-a12b1435d2fe",
  },
  {
    image: LogoRetailTimes,
    link: "https://www.retailtimes.co.uk/social-supermarket-launches-team-gifting-platform-an-efficient-new-portal-for-corporate-gift-buying/",
  },
  {
    image: LogoReuters,
    link: "https://www.reuters.com/article/us-global-retail-social-impact-trfn-idUSKBN1YR02N",
  },
  {
    image: LogoPalife,
    link: "https://palife.co.uk/news/social-impact-christmas-hampers-seeing-3x-as-much-interest-this-year/",
  },
]

interface RecommendationsProps {
  backgroundColor?: string
  title: string
}

const LPRecommendationsBlock: React.FC<RecommendationsProps> = ({
  title,
  backgroundColor = white,
}): JSX.Element => (
  <LPBlock backgroundColor={backgroundColor}>
    <LPBody>
      <LpIndentedTitle>{title}</LpIndentedTitle>
      <CarouselContainer>
        <Carousel
          swiperProps={{
            autoplay: { delay: 2000, disableOnInteraction: true },
          }}
          loop
          showArrows
        >
          {logos.map(({ image, link }) => (
            <SwiperSlide key={link}>
              <Slide>
                <a href={link} target="_blank">
                  <img src={image} alt="Resource logo" />
                </a>
              </Slide>
            </SwiperSlide>
          ))}
        </Carousel>
      </CarouselContainer>
    </LPBody>
  </LPBlock>
)

export default LPRecommendationsBlock

import * as React from "react"
import styled from "styled-components"
import { grey } from "../../constants/colors"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import Description from "../../components/landing-page/Description"
import AsSeenBlock from "../../components/landing-page/AsSeenBlock"
import IconFiveStar from "../../../svg/icon-5-stars.inline.svg"
import ImageUrbasplash from "../../../images/image-urbansplash.jpg"
import ImageCliffordChance from "../../../images/image-clifford-chance.jpg"
import ImageWagestream from "../../../images/image-wagestream.jpg"
import ImageSlalom from "../../../images/image-slalom.jpg"

const TrustedWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 90px 0;
`

const RateWrapper = styled.div`
  text-align: center;
`

const trustedItems = {
  rateText: "An Average of 4.76 rating based on 76 reviews",
  title: "We're trusted by",
  brandLogos: [
    { image: ImageUrbasplash },
    { image: ImageCliffordChance },
    { image: ImageWagestream },
    { image: ImageSlalom },
  ],
}

const TrustedBlock = () => (
  <ColorWrapper background={grey}>
    <TrustedWrapper>
      <RateWrapper>
        <IconFiveStar />
        <Description margin="10px 0 0 0">{trustedItems.rateText}</Description>
      </RateWrapper>
      <AsSeenBlock data={trustedItems} />
    </TrustedWrapper>
  </ColorWrapper>
)

export default TrustedBlock

import * as React from "react"
import styled from "styled-components"
import { gillFont } from "../../../../../../../constants/fonts"
import { colors } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  font-family: ${gillFont};
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
`

const Select = styled.select`
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid ${({ valid }) => (valid ? colors.otherBorderGrey : colors.rouge)};
`

const Option = styled.option`
  background: white;
  color: ${colors.lightBlack};
  font-family: ${gillFont};
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 52px;
  ${({ isSelected }) => isSelected && `color: ${colors.skyBlue};`}
  :hover {
    background: ${colors.skyBlue};
    color: ${colors.white};
  }
`

const handleChange = (state, onChange) => event => {
  onChange(event.target.name, event.target.value)
}

const options = ["1-50", "51-99", "100-199", "200-1000", "1000+"]

const CompanySizeField = ({ state, onChange, validate }) => {
  const isValid = validate ? CompanySizeField.isValid(state) : true
  return (
    <Container>
      <Label htmlFor="companySize">Company Size</Label>
      <Select
        onChange={handleChange(state, onChange)}
        value={state.companySize}
        name="companySize"
        valid={isValid}
      >
        <Option value="" disabled selected>
          Choose an option..
        </Option>
        {options.map(option => (
          <Option
            isSelected={option.toLowerCase() === state.companySize}
            value={option.toLowerCase()}
          >
            {option}
          </Option>
        ))}
      </Select>
    </Container>
  )
}

CompanySizeField.isValid = state => {
  return Boolean(state.companySize)
}

export default CompanySizeField

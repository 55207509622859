import * as React from "react"
import TextArea from "../../../../../../form/TextArea"

const MessageField = ({ onChange, state }) => {
  return (
    <TextArea
      name="message"
      onChange={onChange}
      label="Message"
      height="211px"
      value={state.message}
      validate={state.validate}
      isValid={MessageField.isValid(state)}
      disabled={false}
    />
  )
}

MessageField.isValid = state => {
  return true
}

export default MessageField

import * as React from "react"
import FormFieldProps from "../../../../../../form/FormFieldProps"
import OutlinedInput from "../../../../../../form/OutlinedInput"
import { EnquiryFormStateType } from "../EnquiryForm"

const LastNameField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<EnquiryFormStateType>) => {
  return (
    <OutlinedInput
      name="lastName"
      onChange={onChange}
      value={state.lastName}
      label="Last Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={LastNameField.isValid(state)}
    />
  )
}

LastNameField.isValid = state => {
  return state.lastName
}

export default LastNameField

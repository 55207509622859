import * as React from "react"
import styled from "styled-components"

const Container = styled.div<{ background?: string; height?: string }>`
  position: relative;
  ${({ background }) => background && `background: ${background};`};
  background-size: cover;
  width: 100%;
  ${({ height }) => height && `height: ${height};`};
`

interface ColorWrapperProps {
  background?: string
  height?: string
  className?: string
  children?: React.ReactNode
}

const ColorWrapper: React.FC<ColorWrapperProps> = ({
  background,
  height,
  className = "",
  children,
}): JSX.Element => (
  <Container background={background} height={height} className={className}>
    {children}
  </Container>
)

export default ColorWrapper
